<!--  -->
<template>
	<div class="login-page">
		<div class="login-content flex">
			<div class="left-layout">
				<img class="cover" src="" alt="" />
			</div>
			<div class="right-layout">
				<div class="nav-title">登录</div>
				<el-form class="input-wrap" ref="loginFormRef" :model="loginInfo" :rules="loginRules" inline hide-required-asterisk>
					<el-form-item label="账   号 :" prop="account">
						<el-input v-model="loginInfo.account"></el-input>
					</el-form-item>
					<el-form-item label="密   码 :" prop="password">
						<el-input type="password" v-model="loginInfo.password"></el-input>
					</el-form-item>
				</el-form>
				<!-- <div class="forget" @click="goToResetPasswordPage"><i class="el-icon-question"></i>忘记密码</div> -->
				<div class="login-btn" @click="login">登录</div>
			</div>
		</div>
	</div>
</template>

<script>
import { $login } from "../services/login";
export default {
	name: "Login",
	data() {
		return {
			loginInfo: {
				account: "",
				password: "",
			},
			loginRules: {
				account: [{ required: true, message: "请输入账号", trigger: "blur" }],
				password: [{ required: true, message: "请输入密码", trigger: "blur" }],
			},
		};
	},
	components: {},

	computed: {},

	created() {},

	methods: {
		login() {
			this.$refs.loginFormRef.validate(async valid => {
				if (!valid) {
					this.$message.error("请输入账号和密码");
					return;
				} else {
					const { data } = await $login({
						account: this.loginInfo.account,
						password: this.loginInfo.password,
						type: 1,
					});
					if (data) {
						localStorage.setItem("token", data._token);
						this.$store.commit("UPDATEDSTATE", { key: "useInfo", value: data });
						this.$router.push({
							path: "/",
						});
					}
				}
			});
		},
		goToResetPasswordPage() {
			this.$router.push({
				path: "/resetPassword",
			});
		},
	},
};
</script>

<style scoped lang="less">
.login-content {
	width: 1200px;
	min-height: calc(100vh - 232px);
	margin: 0 auto;
	padding: 50px 0;
	.left-layout {
		width: 850px;
		.cover {
			width: 800px;
		}
	}
	.right-layout {
		width: 350px;
		.nav-title {
			color: #1c1f21;
			font-size: 28px;
			font-weight: 500;
			margin-bottom: 50px;
		}
		.input-wrap {
			margin-bottom: 20px;
			.el-form-item {
				display: flex;
				justify-content: space-between;
				margin: 0;
				margin-bottom: 40px;
				.el-input {
					width: 280px;
				}
			}
		}
		.forget {
			text-align: right;
			color: #15abee;
			cursor: pointer;
			user-select: none;
		}
		.login-btn {
			float: right;
			margin-top: 30px;
			height: 40px;
			line-height: 40px;
			cursor: pointer;
			font-size: 18px;
			color: #ffffff;
			text-align: center;
			width: 178px;
			background: linear-gradient(90deg, #15abee 0%, #5dccff 100%);
			border-radius: 27px;
		}
	}
}
</style>
